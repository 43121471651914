import React from "react"
import { Link } from "gatsby"
import SideBarMenu from "../sidebar/sidebar"
import Sticky from "react-sticky-el"
import styl from "./header.module.css"
import licon from "../../../static/assets/location-icon.png"
import cicon from "../../../static/assets/call-icon.png"
import micon from "../../../static/assets/mail-icon.png"
import logo from "../../../static/assets/logo.svg"

const Header = () => (
  <header>
    <div className="header-top">
      <div className="container">
        <div className="row justify-content-between align-items-center">
          <div className="col-md-4">
            <div className="header-top-location">
              <p className="header-top-p text-white">
                <img className="img-fluid mr-1" src={licon} alt="imgtext" />
                PO Box 1843 Broomfield CO 80038 USA
              </p>
            </div>
          </div>
          <div className="col-md-8">
            <div className="header-top-contact d-flex justify-content-end align-items-center">
              <Link
                to="tel:7202533430"
                className="header-top-p text-white mr-3"
              >
                <img
                  className="img-fluid mr-1 mb-1"
                  src={cicon}
                  alt="imgtext"
                />
                720-253-3430
              </Link>
              <Link
                to="mailto:mdooley@frontlinepartners.co"
                className="header-top-p text-white"
              >
                <img className="img-fluid mr-1" src={micon} alt="imgtext" />
                mdooley@frontlinepartners.co
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Sticky
      className={styl.stickyWrapper}
      stickyClassName="sticky-header"
      stickyStyle={{ transform: "unset", zIndex: "2" }}
    >
      <div className={`header ${styl.header}`}>
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col">
              <div className="header-logo">
                <Link to="/">
                  <img className="img-fluid" src={logo} alt="imgtext" />
                </Link>
              </div>
            </div>
            <div className="col-4">
              <div className="text-right">
                <SideBarMenu />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Sticky>
  </header>
)

export default Header
