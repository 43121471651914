import React from "react"
import { Link } from "gatsby"
import logo from "../../../static/assets/logo.svg"
import gpStore from "../../../static/assets/googleplaystore.png"
import appleStore from "../../../static/assets/appleappstore.png"
import fbIcon from "../../../static/assets/facebook.svg"
import twitterIcon from "../../../static/assets/twitter.svg"
import instaIcon from "../../../static/assets/instagram.svg"
import youIcon from "../../../static/assets/youtube.svg"
import linkIcon from "../../../static/assets/linkedin.svg"

const footer = props => {
  return (
    <div id="footer">
      <section id="contact">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="contact-content">
                <h2 className="section-title text-center text-white">
                  How can we help you?
                </h2>
                <h5 className="contact-subtitle text-center text-white">
                  <span className="contact-subtitle-border">Get in touch</span>{" "}
                  with us or{" "}
                  <span className="contact-subtitle-border">
                    visit our office
                  </span>{" "}
                  closest to you.
                </h5>
                <hr className="contact-hr" />
                <h4 className="contact-us-title text-center text-white">
                  Contact Us
                </h4>
                <form className="contact-form text-center">
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="name"
                      id="Name"
                      required=""
                    />
                    <label htmlFor="Name">Full Name</label>
                  </div>
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="email"
                      id="Email"
                      required=""
                    />
                    <label htmlFor="Email">Email address</label>
                  </div>
                  <div className="form-group mb-5">
                    <textarea
                      className="form-control"
                      type="message"
                      id="Message"
                      required=""
                    />
                    <label htmlFor="Message">Your Message Here...</label>
                  </div>
                  <button className="btn btn-primary contact-btn" type="button">
                    Sumbit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="bottom-footer">
        <div className="container">
          <div className="row">
            <div className="col-md-5 mb-4">
              <div className="f-about-content">
                <Link className="f-logo-btn" to="#">
                  <img className="img-fluid mb-3" src={logo} alt="imgtext" />
                </Link>
                <p className="section-p text-white">
                  is an accounting, tax and business advisory firm that provides
                  services to small to mid-sized businesses utilizing technology
                  to provide actionable financial data to maximize profits and
                  create a growth path to achieve client's goals.
                </p>
                <hr className="hr-green" />
                <p className="section-p text-white">
                  <span className="font-700">Frontline Partner Insights</span> -
                  Get our latest thinking on your iPhone, iPad, or Android
                  device.
                </p>
              </div>
            </div>
            <div className="col-md-2 mb-4">
              <div className="f-quicklinks-content">
                <h6 className="footer-title">Quick Links</h6>
                <ul className="footer-menu">
                  <li>
                    <a href="#">Contact Us</a>
                  </li>
                  <li>
                    <a href="#">FAQ</a>
                  </li>
                  <li>
                    <a href="#">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="#">Cookie Policy</a>
                  </li>
                  <li>
                    <a href="#">Terms of Use</a>
                  </li>
                  <li>
                    <a href="#">Local Language Information</a>
                  </li>
                  <li>
                    <a href="#">Accessibility Statement</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-2 mb-4">
              <div className="f-home-content">
                <h6 className="footer-title">Home</h6>
                <ul className="footer-menu">
                  <li>
                    <a href="#">INDUSTRIES</a>
                  </li>
                  <li>
                    <a href="#">SERVICES</a>
                  </li>
                  <li>
                    <a href="#">INSIGHTS</a>
                  </li>
                  <li>
                    <a href="#">BLOG</a>
                  </li>
                  <li>
                    <a href="#">CONTACT</a>
                  </li>
                  <li>
                    <a href="#">ABOUT US</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="f-intouch-content">
                <h6 className="footer-title">GET IN TOUCH</h6>
                <p className="section-p text-white">PO BOX 1843</p>
                <p className="section-p text-white">
                  Broomfield
                  <br />
                  CO
                  <br />
                  80038
                  <br />
                  USA
                </p>
                <ul className="footer-menu">
                  <li>
                    <a href="tel:7202533430">720-253-3430</a>
                  </li>
                  <li className="mobile-email">
                    <a href="mailto:mdooley@frontlinepartners.com">
                      mdooley@frontlinepartners.com
                    </a>
                  </li>
                  <li className="mobile-email-d">
                    <a href="mailto:mdooley@frontlinepartners.com">Email Us</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 mb-4">
              <div className="app-content">
                <a className="google-play mr-2" href="#">
                  <img className="img-fluid" src={gpStore} alt="imgtext" />
                </a>
                <a className="app-store" href="#">
                  <img className="img-fluid" src={appleStore} alt="imgtext" />
                </a>
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div className="social-content text-right">
                <ul className="footer-social">
                  <li>
                    <a href="#">
                      <img className="img-fluid" src={fbIcon} alt="imgtext" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        className="img-fluid"
                        src={twitterIcon}
                        alt="imgtext"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        className="img-fluid"
                        src={instaIcon}
                        alt="imgtext"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img className="img-fluid" src={youIcon} alt="imgtext" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img className="img-fluid" src={linkIcon} alt="imgtext" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="branding">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <p className="copyright section-p text-dark mb-0">
                © 1996-2020 Frontline Partners
              </p>
            </div>
            <div className="col-md-6">
              <p className="webriq section-p text-dark mb-0 text-right">
                Designed and Powered by:{" "}
                <a href="" className="font-700 text-dark">
                  WebriQ
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default footer
